<template>
  <div class="select-empl-dept-dialog">
    <!-- 选择开卡员工或部门对话框 -->
    <el-dialog
      title="选择开卡员工或部门"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      top="6vh"
      width="80%"
      @close="onCloseDialog"
    >
      <div class="select-box">
        <!-- 选择员工 -->
        <div class="empl-box">
          <div class="top">
            <el-select
              v-model="emplActive"
              placeholder="选择员工"
              size="small"
              @change="onChangeSelect($event, 1)"
              @focus="popoverVisible = false"
            >
              <el-option
                v-for="(item, index) in emplList"
                :key="index"
                :label="
                  `${item.EmplNo}-${item.EmplName} (职位:${item.DutyName}, 级别:${item.LevelName})`
                "
                :value="index"
              >
              </el-option>
            </el-select>

            <div class="history">
              <el-popover
                v-model="popoverVisible"
                placement="bottom"
                width="1000"
                trigger="click"
              >
                <div class="history-body">
                  <el-radio-group v-model="active" size="small">
                    <el-radio-button
                      v-for="(item, index) in [
                        { Name: '员工业绩提成' },
                        { Name: '部门售卡业绩' },
                      ]"
                      :key="index"
                      :label="index"
                      >{{ item.Name }}</el-radio-button
                    >
                  </el-radio-group>

                  <div v-show="active == 0">
                    <el-table
                      id="exprot-table"
                      border
                      stripe
                      :data="orgKeepEmpls"
                      height="400"
                      size="mini"
                    >
                      <el-table-column label="记账日期">
                        <template slot-scope="scope">
                          {{
                            util.dateFormat(
                              "YYYY-mm-dd",
                              new Date(scope.row.SoftwareDate * 1000)
                            )
                          }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="类型"
                        prop="KeepTypeText"
                      ></el-table-column>
                      <el-table-column
                        label="工号"
                        prop="EmplNo"
                      ></el-table-column>
                      <el-table-column
                        label="姓名"
                        prop="EmplName"
                      ></el-table-column>
                      <el-table-column
                        label="可分配业绩"
                        prop="SharePerfor"
                      ></el-table-column>
                      <el-table-column label="占比(%)">
                        <template slot-scope="scope">
                          {{ scope.row.PerforRatio * 100 }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="业绩(元)"
                        prop="PerforMoney"
                      ></el-table-column>
                      <el-table-column
                        label="可分配提成"
                        prop="ShareRoya"
                      ></el-table-column>
                      <el-table-column label="占比(%)">
                        <template slot-scope="scope">
                          {{ scope.row.RoyaRatio * 100 }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="提成(元)"
                        prop="RoyaMoney"
                      ></el-table-column>
                    </el-table>
                  </div>

                  <div v-show="active == 1">
                    <el-table
                      id="exprot-table"
                      border
                      stripe
                      :data="orgKeepDepts"
                      height="400"
                      size="mini"
                    >
                      <el-table-column label="记账日期">
                        <template slot-scope="scope">
                          {{
                            util.dateFormat(
                              "YYYY-mm-dd",
                              new Date(scope.row.SoftwareDate * 1000)
                            )
                          }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="类型"
                        prop="KeepTypeText"
                      ></el-table-column>
                      <el-table-column
                        label="营业部门"
                        prop="DeptName"
                      ></el-table-column>
                      <!-- <el-table-column
                        label="可分配业绩"
                        prop="SharePerfor"
                      ></el-table-column> -->
                      <el-table-column label="占比(%)">
                        <template slot-scope="scope">
                          {{ scope.row.PerforRatio * 100 }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="业绩(元)"
                        prop="PerforMoney"
                      ></el-table-column>
                    </el-table>
                  </div>
                </div>

                <el-button size="small" slot="reference"
                  >查看已还款业绩提成</el-button
                >
              </el-popover>
            </div>

            <div class="empl-roya">
              <el-switch
                :value="submitData.AvgRoya ? true : false"
                active-text="平分业绩提成"
                @change="onChangeAvgRoya"
              >
              </el-switch>
            </div>
          </div>

          <div class="empl-list">
            <div
              class="empl-item"
              v-for="(item, index) in submitData.EmplRoyaList"
              :key="index"
            >
              <div class="info">
                {{ `${item.EmplName}-${item.DutyName}-${item.LevelName}` }}
              </div>

              <div class="content">
                <div class="input-item">
                  <span>可分配业绩：</span>
                  <span>{{ item.SharePerfor }}</span>
                </div>

                <div class="input-item">
                  <span>占比：</span>
                  <el-input
                    size="mini"
                    v-model="item.PerforRatio"
                    @change="onChangeInput(item, true, false)"
                  >
                    <template slot="append">%</template>
                  </el-input>
                </div>

                <div class="input-item">
                  <span>业绩：</span>
                  <el-input
                    size="mini"
                    v-model="item.PerforMoney"
                    @change="onChangeInput(item, true, true)"
                  ></el-input>
                </div>

                <div class="input-item">
                  <span>可分配提成：</span>
                  <span>{{ item.ShareRoya }}</span>
                </div>

                <div class="input-item">
                  <span>占比：</span>
                  <el-input
                    size="mini"
                    v-model="item.RoyaRatio"
                    @change="onChangeInput(item, false, false)"
                  >
                    <template class="prent" slot="append">%</template>
                  </el-input>
                </div>

                <div class="input-item">
                  <span>提成：</span>
                  <el-input
                    size="mini"
                    v-model="item.RoyaMoney"
                    @change="onChangeInput(item, false, true)"
                  ></el-input>
                </div>
              </div>

              <div class="btn">
                <el-button
                  type="danger"
                  size="mini"
                  :disabled="deleteDisabled"
                  @click="onDeleteItem('EmplRoyaList', index)"
                  >删除</el-button
                >
              </div>
            </div>
          </div>
        </div>

        <!-- 选择部门 -->
        <div class="dept-box">
          <div class="top">
            <el-select
              v-model="deptActive"
              placeholder="选择部门"
              size="small"
              @change="onChangeSelect($event, 2)"
            >
              <el-option
                v-for="(item, index) in deptList"
                :key="index"
                :label="item.DeptName"
                :value="index"
              >
              </el-option>
            </el-select>
          </div>

          <div class="dept-list">
            <div
              class="dept-item"
              v-for="(item, index) in submitData.DeptRoyaList"
              :key="index"
            >
              <div class="info">{{ item.DeptName }}</div>

              <div class="content">
                <div class="input-item">
                  <span>可分配业绩：</span>
                  <span>{{ item.SharePerfor }}</span>
                </div>

                <div class="input-item">
                  <span>占比：</span>
                  <el-input
                    size="mini"
                    v-model="item.PerforRatio"
                    @change="onChangeInput(item, true, false)"
                  >
                    <template slot="append">%</template>
                  </el-input>
                </div>

                <div class="input-item">
                  <span>业绩：</span>
                  <el-input
                    size="mini"
                    v-model="item.PerforMoney"
                    @change="onChangeInput(item, true, true)"
                  ></el-input>
                </div>
              </div>

              <div class="btn">
                <el-button
                  type="danger"
                  size="mini"
                  :disabled="deleteDisabled"
                  @click="onDeleteItem('DeptRoyaList', index)"
                  >删除</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="onSubmitData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Repay from "@/api/repay.js";
export default {
  props: {},

  data() {
    return {
      active: 0,
      emplActive: "",
      deptActive: "",
      emplList: [], // 员工列表
      deptList: [], // 部门列表
      orgKeepEmpls: [],
      orgKeepDepts: [],
      submitData: {},
      emplRoya: false,
      dialogVisible: false, // 对话框显示
      popoverVisible: false,
      deleteDisabled: false,
    };
  },

  methods: {
    // 初始化还款业绩提成数据
    async initRepayRoya() {
      try {
        let { data } = await Repay.initRepayRoya({
          debt_guid: this.submitData.DebtGuid,
          repay_bill_guid: this.submitData.BillGuid || "",
        });
        this.emplList = data.emplList;
        this.deptList = data.deptList;
        this.orgKeepEmpls = data.orgKeepEmpls;
        this.orgKeepDepts = data.orgKeepDepts;
        
        this.submitData.EmplRoyaList = data.editKeepEmpls;
        this.submitData.DeptRoyaList = data.editKeepDepts;
      } catch (err) {
        console.log(err);
      }
    },

    // 计算还款业绩提成
    async calcuRepayRoya() {
      let submitData = JSON.parse(JSON.stringify(this.submitData))
      submitData = {
        ...submitData,
        RepayMoney: submitData.Payments[0].PayMoney
      }
      delete submitData.Payments
      try {
        submitData.AvgRoya = submitData.AvgRoya ? 1 : 0;
        let { data } = await Repay.calcuRepayRoya(submitData);
        this.submitData = {
          ...this.submitData,
          EmplRoyaList: data.emplRoyaList,
          DeptRoyaList: data.deptRoyaList,
        };
      } catch (err) {
        console.log(err);
      }
    },

    // 打开对话框
    onShowDialog(submitData = {}) {
      this.submitData = JSON.parse(JSON.stringify(submitData));
      this.initRepayRoya();
      this.dialogVisible = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.dialogVisible = false;
      this.resetData();
    },

    // 平分业绩提成
    onChangeAvgRoya(event) {
      this.submitData.AvgRoya = event;
      window.sessionStorage.setItem("AvgRoya", event ? 1 : 0);
      this.calcuRepayRoya();
    },

    // 输入框改变事件
    onChangeInput(event, isPerfor, isRatio) {
      let moneyName = "RoyaMoney",
        ratioName = "RoyaRatio",
        share = "ShareRoya";

      if (isPerfor) {
        moneyName = "PerforMoney";
        ratioName = "PerforRatio";
        share = "SharePerfor";
      }

      if (event[share] == 0) {
        event[ratioName] = 0;
        return (event[moneyName] = 0);
      }

      if (isRatio) {
        // 计算占比
        event[moneyName] = Number(Number(event[moneyName]).toFixed(2));
        event[ratioName] = Math.floor((event[moneyName] / event[share]) * 100);
      } else {
        // 计算金额
        event[ratioName] = Number(event[ratioName]);
        event[moneyName] = Number(
          (event[share] * (event[ratioName] / 100)).toFixed(2)
        );
      }
    },

    // 员工或部门下拉框改变事件
    onChangeSelect(index, type) {
      let keyName = "",
        text = "";

      let data = {},
        arr = [];

      if (type == 1) {
        keyName = "EmplRoyaList";
        text = "员工";
        data = this.emplList[index];
        arr = this.submitData.EmplRoyaList.filter(
          (item) =>
            item.EmplGuid == data.EmplGuid && item.LevelGuid == data.LevelGuid
        );
      } else {
        keyName = "DeptRoyaList";
        text = "部门";
        data = this.deptList[index];
        arr = this.submitData.DeptRoyaList.filter(
          (item) => item.DeptGuid == data.DeptGuid
        );
      }

      if (arr.length) {
        return this.$message.info(`该${text}已添加过~`);
      }

      this.submitData[keyName].push(data);

      this.calcuRepayRoya();
    },

    // 重置页面数据
    resetData() {
      this.emplActive = "";
      this.deptActive = "";
    },

    // 删除员工或部门事件
    onDeleteItem(type, index) {
      this.$delete(this.submitData[type], index);
      this.calcuRepayRoya();
      this.resetData();
    },

    // 确认按钮点击事件
    onSubmitData() {
      this.$emit("confirm", this.submitData);
      this.onCloseDialog();
    },
  },
};
</script>

<style lang="less" scoped>
.select-empl-dept-dialog {
  .select-box,
  .select-box > div {
    display: flex;
    flex-direction: column;
  }

  .select-box {
    > div {
      flex: 1;

      .top {
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        align-items: center;
        padding: 20px;

        .history {
          flex: 1;

          > * {
            margin: 0 10px;
          }
        }

        .el-select {
          width: 50%;
        }
      }

      .empl-list,
      .dept-list {
        height: 260px;
        max-height: 260px;
        display: block;
        overflow-y: scroll;
        // display: flex;
        // flex-direction: column;

        .empl-item,
        .dept-item {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 20px;
          box-sizing: border-box;

          > div {
            margin: 20px 0;
          }

          .content {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .input-item {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin: 0 10px;

              ::v-deep .el-input {
                width: 100px !important;
              }

              ::v-deep .el-input-group__append {
                padding: 0 5px !important;
              }
            }
          }
        }
      }
    }
  }
}

.history-body {
  padding: 10px;
  .el-radio-group {
    margin-bottom: 10px;
  }
}
</style>
