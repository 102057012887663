import { request } from "../utils/axios.js";

export default {
  // 会员 ==> 会员还款欠款
  initDebtMaintList(params = {}) {
    return request("get", `/repay/init_debt_maint_list`, params);
  },

  getDebtMainListPages(params = {}) {
    return request("get", `/repay/get_debt_maint_list`, params);
  },

  initNewRepay(params = {}) {
    return request("get", `/repay/init_new_repay`, params);
  },

  initRepayRoya(params = {}) {
    return request("get", `/repay/init_repay_roya`, params);
  },

  calcuRepayRoya(params = {}) {
    return request("post", `/repay/calcu_repay_roya`, params);
  },

  applyRepay(params = {}) {
    return request("post", `/repay/apply_repay`, params, false, true, false, true);
  },

  getRepayRecords(params = {}) {
    return request("get", `/repay/get_repay_records`, params);
  },

  reverseRepay(params = {}) {
    return request("get", `/repay/reverse_repay`, params, false, true, false, true);
  },

  alterRepaySoftwareDate(params = {}) {
    return request("post", `/repay/alter_repay_software_date`, params);
  },

  voidDebt(params = {}) {
    return request("get", `/repay/void_debt`, params, false, true, false, true);
  },

  saveEditingRepayRoya(params = {}) {
    return request("post", `/repay/save_editing_repay_roya`, params);
  },
};
