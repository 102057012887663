<template>
  <div class="add-payment-dialog">
    <!-- 新增还款记录对话框 -->
    <el-dialog
      title="新增还款记录"
      :visible.sync="dialogVisible"
      top="3%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
      v-loading="loading"
    >
      <el-form ref="formRules" :model="debtInfo" label-width="90px" size="mini">
        <el-form-item label="欠款信息："></el-form-item>

        <el-row :gutter="rowGutter">
          <el-col :span="colSpan">
            <el-form-item label="欠款类型">
              {{ changeBillType(dataInfo.BillType) }}
            </el-form-item>
          </el-col>

          <el-col :span="colSpan">
            <el-form-item label="会员卡号">
              {{ dataInfo.BillNo }}
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="rowGutter">
          <el-col :span="colSpan">
            <el-form-item label="会员卡名称">
              {{ debtInfo.CardName }}
            </el-form-item>
          </el-col>

          <el-col :span="colSpan">
            <el-form-item label="会员名称">
              {{ debtInfo.MemberName }}
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="rowGutter">
          <el-col :span="colSpan">
            <el-form-item label="会员电话">
              {{ debtInfo.MemberPhone }}
            </el-form-item>
          </el-col>

          <el-col :span="colSpan">
            <el-form-item label="欠款时间">
              {{
                util.dateFormat(
                  "YYYY-mm-dd HH:MM:SS",
                  new Date(debtInfo.DebtTime * 1000)
                )
              }}
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="rowGutter">
          <el-col :span="colSpan">
            <el-form-item label="欠款金额">
              {{ debtInfo.DebtMoney }}
            </el-form-item>
          </el-col>

          <el-col :span="colSpan">
            <el-form-item label="已还金额">
              {{ debtInfo.RepayMoney }}
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="rowGutter">
          <el-col :span="colSpan">
            <el-form-item label="尚欠金额">
              {{ debtInfo.SurpMoney }}
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item
          label="支付方式："
          style="margin-top: 40px;"
        ></el-form-item>

        <el-row
          :gutter="rowGutter"
          v-for="(rowItem, rowIndex) in paymentsRowCount"
          :key="'r' + rowIndex"
        >
          <el-col
            :span="colSpan"
            v-for="(colItem, colIndex) in paymentsColCount"
            :key="'c' + colIndex"
          >
            <div
              v-if="
                paymentsColCount * rowIndex + colIndex < cashPayments.length
              "
              class="col-item"
            >
              <div
                class="title"
                @click="
                  resetPaymentPrice(paymentsColCount * rowIndex + colIndex)
                "
              >
                <span>{{
                  cashPayments[paymentsColCount * rowIndex + colIndex]
                    .PaymentName
                }}</span>
                <span class="dfWebIcon dfWebIcon-refresh-circle"></span>
              </div>

              <el-input
                size="mini"
                v-model="
                  cashPayments[paymentsColCount * rowIndex + colIndex].PayMoney
                "
                @input="onInputPayMoney(paymentsColCount * rowIndex + colIndex)"
                @change="onChangePayMonry"
              >
                <template slot="append">元</template>
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="rowGutter">
          <el-col :span="colSpan">
            <div class="col-item">
              <div class="title">
                <span>选择支付卡</span>
              </div>

              <el-select
                :value="vipInfo.PaymentGuid"
                placeholder="请选择"
                size="mini"
                clearable
                @change="onChangePayment"
                @clear="onClearPayment"
              >
                <el-option
                  v-for="item in vipPayments"
                  :key="item.PaymentGuid"
                  :label="item.PaymentName"
                  :value="item.PaymentGuid"
                >
                </el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :span="colSpan">
            <div class="col-item">
              <div
                class="title"
                @click="
                  vipInfo.PaymentGuid
                    ? resetPaymentPrice(paymentsColCount * rowIndex + colIndex)
                    : ''
                "
              >
                <span>会员卡支付</span>
                <span class="dfWebIcon dfWebIcon-refresh-circle"></span>
              </div>

              <el-input
                size="mini"
                v-model="vipInfo.PayMoney"
                :disabled="!vipInfo.PaymentGuid"
                @input="onInputPayMoney"
                @change="onChangePayMonry"
              >
                <template slot="append">元</template>
              </el-input>
            </div>
          </el-col>

          <el-col :span="12">
            <div class="amount">
              <span>合计：</span>
              <span>{{ amount }}</span>
            </div>
          </el-col>
        </el-row>

        <el-row v-if="dataInfo.BillType > 0" class="btn-box" :gutter="24">
          <el-col>
            <el-button type="primary" @click="onSelectEmplDept"
              >还款业绩提成</el-button
            >

            <!-- <el-button @click="onRepayRecords">已还款业绩提成</el-button> -->
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button
          type="primary"
          :disabled="submitDisabled"
          @click="onSubmitData"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <SelectEmplDeptDialog
      ref="SelectEmplDeptDialog"
      @confirm="onConfirmSubmitData"
    ></SelectEmplDeptDialog>

    <!-- <RepayRecordsTable ref="RepayRecordsTable"></RepayRecordsTable> -->
  </div>
</template>

<script>
import Repay from "@/api/repay.js";
import SelectEmplDeptDialog from "@/views/member/components/select-empl-dept-dialog.vue";
// import RepayRecordsTable from "./repay-records-table.vue";

export default {
  components: { SelectEmplDeptDialog },

  props: {
    dataInfo: {
      // 欠款数据
      type: Object,
      default: () => ({}),
    },

    billTypeList: {
      // 账单类型列表
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      submitData: {
        // 提交数据
        DebtGuid: "", // 欠款id
        AvgRoya: 0, // 是否平分 0 否 1 是
        EmplRoyaList: [], // 已选员工信息列表
        DeptRoyaList: [], // 已选部门信息列表
        Payments: [], // 已选支付方式
      },
      vipInfo: {
        PaymentGuid: "",
        PayMoney: 0,
      },
      tempPayments: {
        Payments: [],
        cashPayments: [],
        amount: 0,
      },
      debtInfo: {},
      cashPayments: [],
      vipPayments: [],
      paymentsRowCount: 0,
      paymentsColCount: 2,
      rowGutter: 24,
      colSpan: 12,
      amount: 0, // 合计金额
      loading: false,
      dialogVisible: false, // 对话框显示
      submitDisabled: false, // 提交按钮禁用
    };
  },

  computed: {
    // 欠款类型转换
    changeBillType() {
      return function(billType) {
        let arr = this.billTypeList.find((item) => item.Value == billType);
        return arr ? arr.Name : "";
      };
    },
  },

  methods: {
    // 初始化还款数据
    async initPageData() {
      let dataInfo = JSON.parse(JSON.stringify(this.dataInfo));
      let avgRoya = window.sessionStorage.getItem("AvgRoya");
      this.loading = true;
      try {
        let submitData = {
          debt_guid: dataInfo.DebtGuid,
        };
        let { data } = await Repay.initNewRepay(submitData);
        data.cashPayments[0].PayMoney = dataInfo.SurpMoney;

        this.cashPayments = data.cashPayments;
        this.amount = dataInfo.SurpMoney;

        this.submitData = {
          DebtGuid: dataInfo.DebtGuid,
          Payments: [this.cashPayments[0]],
          AvgRoya: avgRoya ? Number(avgRoya) : 1,
          EmplRoyaList: [],
          DeptRoyaList: [],
        };

        this.debtInfo = data.debtInfo;
        this.vipPayments = data.vipPayments;
        this.paymentsRowCount = Math.round(
          data.cashPayments.length / this.paymentsColCount
        );
        // console.log(this.paymentsRowCount);
        this.tempPayments = {
          Payments: [this.cashPayments[0]],
          cashPayments: JSON.parse(JSON.stringify(data.cashPayments)),
          amount: dataInfo.SurpMoney,
        };
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 确定提交事件
    async onSubmitData() {
      let { dataInfo, cashPayments, vipInfo, submitData } = this;

      this.submitDisabled = true;

      try {
        // let Payments = cashPayments.filter((item) => item.PayMoney);
        // vipInfo.PaymentGuid ? Payments.push(vipInfo) : "";
        // Payments.forEach((item) => (item.PayMoney = Number(item.PayMoney)));

        // submitData.Payments = Payments
        // console.log(submitData);
        let { data, errcode } = await Repay.applyRepay(submitData);
        if (errcode == 0) {
          this.$message.success("还款成功");
          this.resetData();
          this.onCloseDialog();
          this.$emit("success");
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.submitDisabled = false;
      }
    },

    // 输入框文本输入事件（计算合计金额）
    async onInputPayMoney() {
      let { cashPayments, vipInfo, dataInfo } = this;
      let amount = 0 + Number(vipInfo.PayMoney);
      for (let i = 0; i < cashPayments.length; i++) {
        amount += Number(cashPayments[i].PayMoney);
        if (i == cashPayments.length - 1) {
          this.amount = amount;
          break;
        }
        // if (amount == this.amount) {
        //   this.amount = amount
        //   break;
        // }
      }
    },

    onChangePayMonry() {
      let { tempPayments, cashPayments, vipInfo, dataInfo, amount } = this;
      let Payments = cashPayments.filter((item) => item.PayMoney);
      vipInfo.PaymentGuid ? Payments.push(vipInfo) : "";
      Payments.forEach((item) => (item.PayMoney = Number(item.PayMoney)));

      this.submitData.Payments = Payments;

      // console.log(this.submitData);
      if (
        this.submitData.EmplRoyaList.length > 0 ||
        this.submitData.DeptRoyaList.length > 0
      ) {
        this.$confirm(
          "当前还款已选择员工或部门业绩提成信息，更改还款金额将会还原员工或部门业绩提成，您确定要更改吗？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              let { data, errcode } = await Repay.calcuRepayRoya(
                this.submitData
              );

              this.submitData = {
                ...this.submitData,
                EmplRoyaList: data.emplRoyaList,
                DeptRoyaList: data.deptRoyaList,
              };

              this.tempPayments = {
                Payments: JSON.parse(JSON.stringify(Payments)),
                amount,
                cashPayments: JSON.parse(JSON.stringify(cashPayments)),
              };
            } catch (err) {
              console.log(err);
              this.amount = tempPayments.amount;
              this.submitData.Payments = JSON.parse(
                JSON.stringify(tempPayments.Payments)
              );
              this.cashPayments = JSON.parse(
                JSON.stringify(tempPayments.cashPayments)
              );
            }
          })
          .catch(() => {
            this.amount = tempPayments.amount;
            this.submitData.Payments = JSON.parse(
              JSON.stringify(tempPayments.Payments)
            );
            this.cashPayments = JSON.parse(
              JSON.stringify(tempPayments.cashPayments)
            );
          });
      } else {
        this.tempPayments = {
          Payments: JSON.parse(JSON.stringify(Payments)),
          amount,
          cashPayments: JSON.parse(JSON.stringify(cashPayments)),
        };
      }
    },

    // 选择支付卡事件
    onChangePayment(event) {
      let arr = this.vipPayments.find((item) => item.PaymentGuid == event);
      this.vipInfo = arr;
    },

    resetPaymentPrice(selectndex) {
      let cashPayments = JSON.parse(JSON.stringify(this.cashPayments));
      let { SurpMoney } = this.debtInfo;

      cashPayments.forEach((item, index) => {
        item.PayMoney = 0;
        if (index == selectndex) {
          item.PayMoney = SurpMoney;
        }
      });
      this.cashPayments = cashPayments;
      this.amount = JSON.parse(JSON.stringify(SurpMoney));

      // let total = 0;
      // cashPayments.forEach((item, index) => {
      //   item.PayMoney = parseFloat(item.PayMoney) || 0;
      //   if (item.PaymentGuid == PaymentGuid) {
      //     item.PayMoney = price;
      //   }
      //   if (item.PayMoney) {
      //     total += parseFloat(item.PayMoney);
      //   }
      // });

      // if (price == 0) {
      //   for (let item of paymentList) {
      //     if (item.PaymentGuid == selectedPaymentGuid) {
      //       item.PayMoney = price;
      //       break;
      //     }
      //   }
      // } else if (priceTotal > price) {
      //   let remaining = total - priceTotal;
      //   if (remaining > 0) {
      //     for (let item of paymentList) {
      //       if (item.PayMoney > 0 && item.PaymentGuid != selectedPaymentGuid) {
      //         let money = item.PayMoney - remaining;
      //         remaining = remaining - item.PayMoney;
      //         item.PayMoney = money > 0 ? money : 0;
      //       }
      //       if (remaining <= 0) {
      //         break;
      //       }
      //     }
      //   }
      // } else {
      //   paymentList.forEach((item, index) => {
      //     item.PayMoney = 0;
      //     if (item.PaymentGuid == selectedPaymentGuid) {
      //       item.PayMoney = priceTotal;
      //     }
      //   });
      //   // return paymentList
      // }
      // return paymentList;
    },

    // 清除支付卡事件
    onClearPayment() {
      this.vipInfo = {
        PaymentGuid: "",
        PayMoney: 0,
      };
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetData()
      this.dialogVisible = false;
    },

    // 打开对话框
    onShowDialog() {
      this.$nextTick(() => {
        this.submitData = {};
        this.dialogVisible = true;
        this.initPageData();
      });
    },

    // 打开已还款业绩提成对话框
    // onRepayRecords() {
    //   let { orgKeepDepts, orgKeepEmpls } = this.initData;
    //   this.$refs.RepayRecordsTable.onShowDialog(orgKeepEmpls, orgKeepDepts);
    // },

    // 打开选择开卡员工或部门对话框
    onSelectEmplDept() {
      if (!this.amount) {
        return this.$message.error("至少选择一种支付方式，并输入有效金额");
      }

      this.$refs.SelectEmplDeptDialog.onShowDialog(this.submitData);
    },

    // 对话框点击确定事件
    onConfirmSubmitData(event) {
      // console.log(event);
      this.submitData = event;
    },

    // 重置数据
    resetData() {
      this.submitData = {};
      this.vipInfo = {
        PaymentGuid: "",
        PayMoney: 0,
      };
      this.tempPayments = {
        Payments: [],
        cashPayments: [],
        amount: 0,
      };
      this.amount = 0;
    },
  },
};
</script>

<style lang="less" scoped>
.add-payment-dialog {
  .col-item {
    .flex-row();
    // padding: 0 20px;
    margin-bottom: 20px;

    .title {
      .flex-row();
      justify-content: right;
      width: 100px;
      margin-right: 10px;

      span:first-child {
        text-align: right;
      }

      .dfWebIcon {
        margin-left: 2px;
        line-height: 14px;
        font-size: 14px;
      }
    }

    .el-input {
      flex: 1;
    }

    .el-select {
      flex: 1;
    }

    ::v-deep .el-input-group__append {
      padding: 0 10px;
    }
  }

  .amount {
    width: 100%;
    height: 63px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    box-sizing: border-box;

    span:first-child {
      font-size: 16px;
    }

    span:last-child {
      font-size: 40px;
      color: tomato;
    }
  }
}
</style>
