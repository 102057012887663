<template>
  <div class="class-detail-statis">
    <!-- start 条件过滤区域 -->
    <div class="df-row">
      <div class="df-col">
        <!-- 日期选择器 -->
        <el-date-picker
          v-model="multipleDate"
          :clearable="false"
          size="mini"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <div class="df-col">
        <!-- 账单类型选择器 -->
        <el-select
          v-model="submitData.bill_type"
          placeholder="请选择"
          size="mini"
        >
          <el-option
            v-for="item in billTypeList"
            :key="item.Value"
            :label="item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <!-- 还清状态选择器 -->
        <el-select
          v-model="submitData.paid_off"
          placeholder="请选择"
          size="mini"
        >
          <el-option
            v-for="item in paidOffList"
            :key="item.Value"
            :label="item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <!-- 单号文本 -->
        <el-input
          v-model="submitData.bill_no"
          size="mini"
          placeholder="请输入单号"
        />
      </div>

      <div class="df-col">
        <!-- 手机号文本 -->
        <el-input
          v-model="submitData.word"
          size="mini"
          placeholder="请输入手机号"
        />
      </div>

      <div class="df-col">
        <!-- 查询按钮 -->
        <el-button type="primary" size="mini" @click="getDebtMainListPages">
          查询
        </el-button>
      </div>

      <div class="df-col">
        <!-- 导出表格按钮 -->
        <el-button
          type="primary"
          size="mini"
          :disabled="excelDisabled"
          @click="onExportExcel"
          >导出表格</el-button
        >
      </div>
    </div>
    <!-- end 条件过滤区域 -->

    <div class="df-row">
      <div class="df-col summary_number">
        本次共查询<span class="value">{{ totalRecord }}</span
        >条记录，未还总消费欠款:<span class="value">{{
          surpMoneyInfo.expeSurpMoney
        }}</span
        >元，未还总充值欠款:<span class="value">{{
          surpMoneyInfo.keepSurpMoney
        }}</span
        >元，未还总开卡欠款:<span class="value">{{
          surpMoneyInfo.openSurpMoney
        }}</span>
      </div>
    </div>

    <!-- start 项目分类统计明细表 -->
    <el-table
      id="exprot-table"
      border
      stripe
      :data="repayList"
      :max-height="tableMaxHeight"
      size="mini"
      v-loading="loading"
    >
      <el-table-column label="单号" width="120">
        <template slot-scope="scope">
          <span class="table-empl-name">{{ scope.row.BillNo }}</span>
        </template>
      </el-table-column>
      <el-table-column label="客户">
        <template slot-scope="scope">
          <p class="table-empl-name">{{ scope.row.MemberName }}</p>
          <p>{{ scope.row.MemberPhone }}</p>
        </template>
      </el-table-column>
      <el-table-column label="欠款类型">
        <template slot-scope="scope">
          {{ changeBillType(scope.row.BillType) }}
        </template>
      </el-table-column>
      <el-table-column prop="DebtMoney" label="欠款总额"></el-table-column>
      <el-table-column label="欠款日期">
        <template slot-scope="scope">
          {{ util.formatDate(scope.row.DebtTime, 2) }}
        </template>
      </el-table-column>
      <el-table-column label="是否还清">
        <template slot-scope="scope">
          <span :class="changeClass(scope.row.SurpMoney)">{{
            !scope.row.SurpMoney ? "已还清" : "未还清"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="DebtOperator" label="欠款操作人"></el-table-column>
      <el-table-column label="还款日期/还款金额/还款操作人" width="260">
        <template slot-scope="scope">
          {{
            `${util.formatDate(scope.row.DebtTime, 2)} 还款 ${
              scope.row.RepayMoney
            }元； ${scope.row.DebtOperator}`
          }}
        </template>
      </el-table-column>
      <el-table-column prop="SurpMoney" label="未还金额"></el-table-column>
      <el-table-column v-if="controlDisabled" label="操作" width="300">
        <template slot-scope="scope">
          <!-- <el-button
            v-if="
              !scope.row.SurpMoney ||
                (scope.row.SurpMoney && scope.row.RepayMoney)
            "
            type="primary"
            size="mini"
            @click="onCheckRepay(scope.row)"
            >详情</el-button
          > -->
          <el-button
            type="primary"
            size="mini"
            @click="onCheckRepay(scope.row)"
            >详情</el-button
          >
          <el-button
            v-if="scope.row.SurpMoney"
            type="success"
            size="mini"
            @click="onAddDebt(scope.row)"
            >还款</el-button
          >
          <el-button
            v-if="scope.row.SurpMoney"
            type="danger"
            size="mini"
            :disabled="deleteDisabled"
            @click="onDeleteDebt(scope.row)"
            >作废欠款</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- end 项目分类统计明细表 -->

    <!-- start 分页器 -->
    <el-pagination
      background
      :page-sizes="[10, 15, 20, 25]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!--end 分页器-->

    <!-- 新增还款记录对话款 -->
    <AddPaymentDialog
      ref="AddPaymentDialog"
      :dataInfo="selectedItem"
      :billTypeList="billTypeList"
      @success="onSuccess"
    ></AddPaymentDialog>

    <!-- 查看还款详情列表 -->
    <RepayListTable ref="RepayListTable" :dataInfo="selectedItem" @success="onSuccess"></RepayListTable>
  </div>
</template>

<script>
import Repay from "@/api/repay";
import AddPaymentDialog from "./componenets/add-payment-dialog.vue";
import RepayListTable from "./componenets/repay-list-table.vue";

export default {
  components: { AddPaymentDialog, RepayListTable },

  data() {
    return {
      submitData: {
        // 提交数据
        begin_date: "", // 开始日期
        end_date: "", // 结束日期
        bill_no: "",  // 单号
        word: "", // 关键词
        bill_type: -1,  // 记录的类型
        paid_off: -1, // 是否还清
        page_index: 1,  // 页码
        page_size: 10,  // 页数
      },
      surpMoneyInfo: {  // 所有查询记录的总金额
        expeSurpMoney: 0, // 总消费欠款金额
        keepSurpMoney: 0, // 总充值欠款金额
        openSurpMoney: 0, // 总开卡欠款金额
      },
      selectedItem: {}, // 选中欠款信息
      billTypeList: [], // 类型数据列表
      paidOffList: [],  // 还清数据列表
      multipleDate: [], // 日期选择器数据
      repayList: [], // 表格内容数据列表
      tableData: [], // 表格单元格矩阵
      totalRecord: 0, // 总页数
      tableMaxHeight: "", // 表格最大高度
      loading: false, // 表格加载
      deleteDisabled: false,  // 删除按钮禁用
      controlDisabled: true,  // 表格操作列显示
      excelDisabled: false, // 导出按钮禁用
    };
  },

  computed: {
    // 文字颜色样式
    changeClass() {
      return function(event) {
        return event ? "table-red-font" : "table-green-font";
      };
    },

    // 欠款类型转换
    changeBillType() {
      return function(billType) {
        let arr = this.billTypeList.find((item) => item.Value == billType);
        return arr ? arr.Name : '';
      };
    },
  },

  created() {
    this.$store.dispatch('initRxcode')
    // console.log(this.$store.dispatch('initRxcode'));
    this.getResize();
    this.initPageData();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    this.multipleDate = [
      this.util.getNowMonthToday(),
      this.util.getNowMonthToday(),
    ];
    this.onChangeDate();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 68 + 40 + 52);
    },

    // 初始化数据
    async initPageData() {
      try {
        let { data } = await Repay.initDebtMaintList();
        this.billTypeList = data.billTypeList;
        this.paidOffList = data.paidOffList;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取还款欠款列表（分页）
    async getDebtMainListPages() {
      this.loading = true;
      try {
        let { data } = await Repay.getDebtMainListPages(this.submitData);
        this.repayList = data.list;
        this.totalRecord = data.filter.TotalRecord;
        this.surpMoneyInfo = {
          expeSurpMoney: data.filter.ExpeSurpMoney,
          keepSurpMoney: data.filter.KeepSurpMoney,
          openSurpMoney: data.filter.OpenSurpMoney,
        };
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getDebtMainListPages();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getDebtMainListPages();
    },

    // 日期选择器改变时间
    onChangeDate() {
      this.submitData.begin_date =
        this.multipleDate.length > 0
          ? this.multipleDate[0]
          : this.util.getNowMonthToday();
      this.submitData.end_date =
        this.multipleDate.length > 0
          ? this.multipleDate[1]
          : this.util.getNowMonthToday();
    },

    // 打开新增还款对话框
    onAddDebt(event) {
      // console.log(event);
      this.selectedItem = JSON.parse(JSON.stringify(event));
      this.$refs.AddPaymentDialog.onShowDialog();
    },

    // 还款成功事件
    onSuccess(){
      this.submitData.page_index = 1
      this.getDebtMainListPages()
    },

    // 打开还款记录对话框
    onCheckRepay(event) {
      // console.log(event);
      this.selectedItem = JSON.parse(JSON.stringify(event));
      this.$refs.RepayListTable.onShowDialog();
    },

    // 作废欠款记录
    onDeleteDebt(event) {
      this.deleteDisabled = true;
      // return console.log(event); 

      this.$confirm(
        `作废欠款将无法恢复，您确认作废所选择的欠款单吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(async () => {
        try {
          let submitData = {
            debt_guid: event.DebtGuid,
          };
          let { data, errcode } = await Repay.voidDebt(submitData);
          if (errcode == 0) {
            this.$message.success("欠款记录成功作废");
            this.onSuccess()
          }
        } catch (err) {
          console.log(err);
        } finally {
          this.deleteDisabled = false;
        }
      }).finally(()=>{
        this.deleteDisabled = false
      })
    },

    // 导出表格按钮点击事件
    onExportExcel() {
      this.excelDisabled = true;
      this.controlDisabled = false
      this.$nextTick(() => {
        try {
          this.util.onExportExcel("#exprot-table", "会员欠款还款表");
        } finally {
          this.excelDisabled = false;
          this.controlDisabled = true
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.class-detail-statis {
  // height: cla;
  // position: relative;

  .el-pagination {
    margin-top: 20px;
    text-align: right;
  }
}
</style>
