<template>
  <div class="repay-list-table">
    <el-dialog
      title="查看还款记录"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="90%"
      @close="onCloseDialog"
    >
      <div class="df-row">
        <div class="df-col">
          <el-button type="primary" size="mini" @click="onEditDate"
            >修改日期</el-button
          >
        </div>
      </div>

      <el-table
        border
        stripe
        :data="datalist"
        size="mini"
        v-loading="loading"
        @selection-change="onChangeSelection"
      >
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="会员姓名" width="100">
          <template>
            <div class="member-info">
              <span>{{ memberInfo.MemberName }}</span>
              <span>{{ memberInfo.MemberPhone }}</span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="会员手机"></el-table-column> -->
        <el-table-column label="会员卡号">{{
          memberInfo.VipNo
        }}</el-table-column>
        <el-table-column
          prop="CashPay"
          label="现金支付"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="DebitPay"
          label="借记卡支付"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="CreditPay"
          label="信用卡支付"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="WeChatPay"
          label="微信支付"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="AlipayPay"
          label="支付宝支付"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="CouponPay"
          label="代金券支付"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="VoidDebtMoney"
          label="作废欠款"
          width="90"
        ></el-table-column>
        <el-table-column label="记账日期">
          <template slot-scope="scope">
            {{
              util.dateFormat(
                "YYYY-mm-dd",
                new Date(scope.row.SoftwareDate * 1000)
              )
            }}
          </template>
        </el-table-column>
        <el-table-column label="还款时间" show-overflow-tooltip width="150">
          <template slot-scope="scope">
            {{
              util.dateFormat(
                "YYYY-mm-dd HH:MM:SS",
                new Date(scope.row.RepayTime * 1000)
              )
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="StoresName"
          label="还款门店"
          width="180"
        ></el-table-column>
        <el-table-column label="操作" width="230">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="onSelectDept(scope.row)"
              >修改业绩提成</el-button
            >
            <el-button
              type="danger"
              size="mini"
              :disabled="deleteDisabled"
              @click="onDeleteRepay(scope.row)"
              >作废还款</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="修改记账日期"
      append-to-body
      width="400px"
      :visible.sync="editVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseEdit"
    >
      <el-form label-width="80px">
        <el-form-item label="选择日期">
          <el-date-picker
            class="date-picker"
            v-model="softwareDate"
            value-format="yyyy-MM-dd"
            type="date"
          ></el-date-picker>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseEdit">取 消</el-button>
        <el-button type="primary" :disabled="editDisabled" @click="editRepay"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <SelectEmplDeptDialog
      ref="SelectEmplDeptDialog"
      @confirm="saveEditingRepayRoya"
    ></SelectEmplDeptDialog>
  </div>
</template>

<script>
import Repay from "@/api/repay.js";

import SelectEmplDeptDialog from "@/views/member/components/select-empl-dept-dialog.vue";

export default {
  components: {
    SelectEmplDeptDialog,
  },

  props: {
    dataInfo: {
      // 欠款数据
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      dialogVisible: false,
      editVisible: false,
      editDisabled: false,
      deleteDisabled: false,
      loading: false,
      softwareDate: "",
      memberInfo: {},
      repayBillItems: [],
      datalist: [],
    };
  },
  methods: {
    // 初始化页面数据
    async initPageData() {
      this.loading = true;
      try {
        let submitData = {
          debt_guid: this.dataInfo.DebtGuid,
        };
        let { data } = await Repay.getRepayRecords(submitData);
        this.datalist = data.list;
        this.memberInfo = data.memberInfo;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async saveEditingRepayRoya(event) {
      // console.log(event);
      let submitData = {
        DebtGuid: event.DebtGuid,
        RepayBillGuid: event.BillGuid,
        EmplRoyaList: event.EmplRoyaList,
        DeptRoyaList: event.DeptRoyaList,
      };
      try {
        let { errcode } = await Repay.saveEditingRepayRoya(submitData);
        if (errcode == 0) {
          this.$message.success("修改成功");
          this.initPageData();
        }
      } catch (err) {
        console.log(err);
      }
    },

    //  打开对话框
    onShowDialog() {
      this.$nextTick(() => {
        this.dialogVisible = true;
        this.initPageData();
      });
    },

    // 关闭对话框
    onCloseDialog() {
      this.dialogVisible = false;
    },

    // 打开修改日期对话框
    onShowEdit() {
      this.editVisible = true;
    },

    // 关闭修改日期对话框
    onCloseEdit() {
      this.editVisible = false;
      this.softwareDate = "";
    },

    // 多选框被改变事件
    onChangeSelection(event) {
      // console.log(event);
      this.repayBillItems = event;
    },

    // 打开修改日期对话框
    onEditDate() {
      let { repayBillItems } = this;
      if (!repayBillItems.length) {
        return this.$message.info("请选择要修改记账日期的还款单");
      }
      this.onShowEdit();
    },

    // 修改还款记账日期
    async editRepay() {
      let { repayBillItems, softwareDate } = this;

      if (!softwareDate) {
        return this.$message.info("请选择要还款日期");
      }

      this.editDisabled = true;

      try {
        let arr = [];
        repayBillItems.forEach((item) => arr.push(item.BillGuid));

        let submitData = {
          selectors: arr,
          softwareDate,
        };
        // console.log(submitData);
        let { data, errcode } = await Repay.alterRepaySoftwareDate(submitData);

        if (errcode == 0) {
          this.$message.success("日期修改成功");
          this.initPageData();
          this.onCloseEdit();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.editDisabled = false;
      }
    },

    onSelectDept(event) {
      // console.log(event);
      let param = {
        BillGuid: event.BillGuid,
        DebtGuid: event.DebtGuid,
        EmplRoyaList: [],
        DeptRoyaList: [],
        Payments: [{ PayMoney: event.TotalPay }],
        AvgRoya: 1,
      };
      this.$refs.SelectEmplDeptDialog.onShowDialog(param);
    },

    // 作废还款记录
    async onDeleteRepay(event) {
      this.deleteDisabled = true;

      this.$confirm(
        `作废还款将无法恢复，您确认作废所选择的还款单吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            let submitData = {
              debt_guid: event.DebtGuid,
              bill_guid: event.BillGuid,
            };
            let { data, errcode } = await Repay.reverseRepay(submitData);
            if (errcode == 0) {
              this.$message.success("还款记录成功作废");
              this.$emit("success");
              this.initPageData();
            }
          } catch (err) {
            console.log(err);
          } finally {
            this.deleteDisabled = false;
          }
        })
        .finally(() => {
          this.deleteDisabled = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.repay-list-table {
  .df-row {
    margin-top: 0;
  }

  .el-table {
    .member-info {
      .flex-col;
      align-items: unset;
    }
  }
}
::v-deep .date-picker {
  width: 220px !important;
}
</style>
